/* #mainCard {
  width: 400px;
  height: 600px;
  background: white;
  border-radius: 15px;
  padding: 1em;
  margin: auto;
  margin-top: 50px;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.2);
} */


/* @media (min-width: 1366px) and (max-width: 1920px) {
    html {
        zoom: 100%;
    }
}

@media (min-width: 1280px) and (max-width: 1366px) {
    html {
        zoom: 80%;
    }
} */

header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorInherit.MuiAppBar-positionFixed {
  position: sticky;
}

.rs-picker-popup{
  z-index: 1300 !important;
}

.css-b62m3t-container{
  margin-right: 2%;
}

.rs-picker-popup{
  z-index: 1300 !important;
}

.jss2 {
  height: 0 !important;
  padding-bottom: 0 !important;
}

.stageBox:hover .editBtn {
  display: block;
}

.editBtn {
  display: none;
}

.MuiTypography-body1 {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.css-1ygcj2i-MuiTableCell-root {
  text-align: center !important;
  padding: 4px !important;
}
.css-pdct74-MuiTablePagination-selectLabel {
  margin-top: 15px;
}
.css-levciy-MuiTablePagination-displayedRows {
  margin-top: 15px;
}

.MuiTableCell-sizeMedium {
  text-align: center !important;
}

/* #mainCard .columnContainer #factors {
  color: red;
  position: absolute;
  margin-top: 150px;
  margin-left: 10px;
  color: grey;
  font-weight: 400;
  font-size: 15px;
} */

#mainCard .columnContainer #factors {
  color: red;
  position: absolute;
  margin-top: 150px;
  margin-left: 10px;
  color: grey;
  font-weight: 400;
  font-size: 15px;
}

#mainCard .columnContainer p:first-child {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
#mainCard .columnContainer p:nth-child(2) {
  text-align: center;
}

#mainCard:hover {
  cursor: pointer;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

#arc {
  margin: 0px 85px;
  position: relative;
}
#arc #low {
  position: absolute;
  bottom: -130px;
  left: -5px;
}

#arc #high {
  position: absolute;
  bottom: -130px;
  left: 184px;
}
#arc #currentScoreContainer {
  position: absolute;
  /* bottom: -130px; */
  left: 65px;
  color: rgba(0, 0, 0, 0.7);
  margin: 0 auto;
  text-align: center;
  margin-top: 10%;
}

#arc #currentScore {
  font-size: 45px;
  font-weight: bolder;
}

#arc #currentScoreDetail {
  font-size: 16px;
  font-weight: bold;
}

.line {
  height: 10px;
  border: 1px solid grey;
}

#bad {
  width: 200px;
  height: 100px; /* as the half of the width */
  border-top-left-radius: 110px; /* 100px of height + 10px of border */
  border-top-right-radius: 110px; /* 100px of height + 10px of border */
  border: 10px solid #ff6364;
  border-bottom: 0;
  clip-path: polygon(0 0, 45% 0, 48% 100%, 0% 100%);
  position: absolute;
}

.bad-selected {
  width: 200px;
  height: 100px; /* as the half of the width */
  border-top-left-radius: 110px; /* 100px of height + 10px of border */
  border-top-right-radius: 110px; /* 100px of height + 10px of border */
  border: 15px solid #ff6364;
  border-bottom: 0;
  clip-path: polygon(0 0, 45% 0, 48% 100%, 0% 100%);
  position: absolute;
}

#poor {
  width: 200px;
  height: 100px; /* as the half of the width */
  border-top-left-radius: 110px; /* 100px of height + 10px of border */
  border-top-right-radius: 110px; /* 100px of height + 10px of border */
  border: 10px solid #ff883a;
  border-bottom: 0;
  clip-path: polygon(69% 0, 45% 0, 48% 100%, 49% 100%);
  position: absolute;
}

#fair {
  width: 200px;
  height: 100px; /* as the half of the width */
  border-top-left-radius: 110px; /* 100px of height + 10px of border */
  border-top-right-radius: 110px; /* 100px of height + 10px of border */
  border: 10px solid #fdd42d;
  border-bottom: 0;
  clip-path: polygon(69% 0, 100% 20%, 48% 100%, 49% 100%);
  position: absolute;
}

#good {
  width: 200px;
  height: 100px; /* as the half of the width */
  border-top-left-radius: 110px; /* 100px of height + 10px of border */
  border-top-right-radius: 110px; /* 100px of height + 10px of border */
  border: 10px solid #0192f5;
  border-bottom: 0;
  clip-path: polygon(100% 38%, 100% -5%, 49% 100%, 49% 100%);
  position: absolute;
}

#excellent {
  width: 200px;
  height: 100px; /* as the half of the width */
  border-top-left-radius: 110px; /* 100px of height + 10px of border */
  border-top-right-radius: 110px; /* 100px of height + 10px of border */
  border: 10px solid #3fd796;
  border-bottom: 0;
  clip-path: polygon(100% 37%, 100% 100%, 48% 100%, 49% 100%);
  position: absolute;
}

.accent {
  color: grey;
  font-size: 15px;
}

.grid {
  height: 300px;
  width: 100%;
  display: grid;
  margin: 5px auto;
  padding: 5px -5px;
  margin-top: 190px;
  grid-template-columns: 1fr 1fr;
}
.gridBox .gridDetail .mainInfo {
  font-size: 20px;
  font-weight: 600;
}
.gridBox .gridDetail .detailInfo {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.4);
}

#dot {
  height: 75px;
  width: 10px;
  border-radius: 50%;
  margin: auto;
  background: white;
  border: 25px solid grey;
  display: block;
  clip-path: polygon(50% 0%, 46% 100%, 54% 100%);
  margin-top: -80%;
}

.outer {
  position: relative;
  display: inline-block;
  height: 100px;
  width: 100px;
  background: lightgray;
  border-radius: 50%;
}
.needle {
  height: 95%;
  width: 100%;
  position: absolute;
  /* top: 0; */
  left: 0;
  margin-top: -13%;
  border-radius: 50%;
  /* transition: all 3s; */
  /* transform: rotate(90deg); */
}

.needle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(50% - 2.5px);
  height: 60%;
  width: 14px;
  background: dodgerblue;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(180deg);
}
.onHover .editIc {
  visibility: hidden;
}
.onHover:hover .editIc {
  visibility: visible;
  animation: editProfilePic 0.5s ease-in-out;
}

@keyframes editProfilePic {
  0% {
    padding-top: 74%;
  }
  100% {
    padding-top: 40%;
  }
}

.fetching {
  position: absolute;
  width: 5.5rem;
  left: 4.2rem;
  top: 0.4rem;
  z-index: 1000;
  opacity: 0.8;
  pointer-events: none;
}
.origin {
  position: absolute;
  clip-path: circle();
  margin-top: 68%;
  margin-left: -21%;
}

/* Layout css */

aside a img {
  transition: all 300ms ease;
}

aside a:last-child {
  position: absolute;
  bottom: 2rem;
}

aside a.active {
  background-color: rgba(139, 167, 252, 0.2);
  margin-left: 0;
}

aside a.active:before {
  content: "";
  width: 5px;
  height: 100%;
  background-color: white;
}

aside a.active img {
  color: var(white);
  margin-left: calc(1rem - 6px);
}

aside a:hover img {
  margin-left: 0.8rem;
  color: white;
  text-decoration: none;
}
