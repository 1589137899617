.layout {
  min-height: 100vh;
  display: flex;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.page {
  height: 92.8vh;
  overflow: auto;
  overflow-x: hidden;
  background-color: #f6faff;
}
.containerRight11 {
  background-color: #fff;
  min-height: 100vh;
  margin: 0 auto;
  margin-left: 230px;
  width: calc(100% - 230px);
}
.container11 {
  background-color: #eee;
  min-height: 100%;
  margin: 0 auto;
}
