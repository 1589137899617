.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  padding: 5px;
}

.MuiButton-root {
  text-transform: none !important;
}
.MuiButton-root:hover {
  color: #0a2472;
  font-weight: 600;
  background-color: rgba(112, 148, 255, 0.3) !important;
}

.primary-btn {
  color: #fff !important;
  background-color: #0a2472 !important;
}
/* width */
/* ::-webkit-scrollbar {
  width: 6px;
  height: 4px;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0a2472;
  border-radius: 100px;
}
