.sgn_body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-attachment: fixed;
}

.container {
  /* max-width: 25rem; */
  max-width: 30rem;
  width: 100%;
  background-color: #ffffff;
  padding: 3rem;
  box-shadow: 0px 48px 128px 7px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}

.container .title .h3 {
  margin: 0 auto;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  padding: 0.4rem 0;
  color: #1d1d1d;
}

.container .title .cancel-btn {
  display: block;
  margin-left: auto;
  cursor: pointer;
}

.container .title .roopya_logo {
  display: block;
  margin: 0.5rem auto;
}

.content {
  margin-top: 2rem;
}

.forget_password {
  font-weight: 600;
  font-size: 0.6rem;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #2b378d;
  margin: 0.6rem 0;
}

@media (max-width: 584px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 347px) {
  .tab .btn_div button {
    height: 100%;
    font-size: 0.7rem;
  }
}

@media (max-width: 416px) {
  input,
  .label .text {
    font-size: 0.4rem;
  }
}

@media (max-width: 385px) {
  input,
  .label .text {
    font-size: 0.3rem;
  }

  .name .label .text {
    font-size: 0.6rem;
  }
}

/* added sign up page style  */
.sign_up_form .i_am_signup_as {
  font-weight: 500;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
  color: #2b378d;
}

/* Tabs Start ---------------- */

.tab {
  overflow: hidden;
  margin-bottom: 0.7rem;
  /* width: 100%; */
}

.tab .btn_div {
  width: 50%;
  text-align: center;
  float: left;
}

.tab .btn_div button {
  width: 100%;
  text-align: center;
}

.cstm_icon {
  text-align: right;
  position: relative;
  left: 90%;
  bottom: 38px;
  /* background-color: #e8f0fe; */
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill,
label {
  top: -45px;
  position: sticky;
}

.forget_password_div {
  margin: 12px 0 20px 0;
  text-align: center;
}

.error_cstmmsg {
  font-size: 0.65rem !important;
  margin-left: 37px !important;
}

.error_cstmmsgeye {
  position: relative;
  left: 0;
  bottom: 25px;
  font-size: 0.65rem !important;
  margin-left: 37px !important;
}

/* Style for tab links */
.tab .a {
  display: inline-block;
  color: black;
  padding: 0.6rem;
  transition: 0.3s;
  font-weight: 500;
  font-size: 0.88rem;
  text-align: center;
}

/* Style for active tab link */
.tab .a.active {
  background: rgba(0, 122, 255, 0.1) !important;
  /* border-top: 1px solid #007AFF; */
  border-radius: 0px !important;
  color: #2b378d !important;
  border-bottom: 2px solid #2b378d;
}

.tab .a {
  border-radius: 1px !important;
  display: inline-block;
  margin-right: 30px;
  position: relative;
  margin-bottom: 0;
  background-color: unset;
}

.tab .a:hover {
  border: 1px solid #dee2e6;
  border-radius: 6px !important;
}

.tab .a:last-child {
  margin-right: 0px;
}

.tab .a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #007aff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.tab .a:hover:before {
  width: 100%;
}

/* Style for tab content stop --------- */

/* form start ----------- */
.tab_pane .frm_fld {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  justify-content: space-between;
}

/* input box styling start -------------- */

.input_container {
  position: relative;
  width: 100%;
}

.input_container input {
  border: 1px solid #dadada;
  box-sizing: border-box;
  padding: 0.8rem;
  width: 100%;
  border-radius: 4px;
  font-size: 2rem;
  /* background-color: #e8f0fe; */
}

.label {
  position: absolute;
  top: 13px;
  bottom: 0;
  left: 3%;
  display: flex;
  align-items: center;
  pointer-events: none;
  /* width: 90%; */
  display: flex;
  justify-content: space-between;
  display: none;
  transition: all 0.3s ease-out;
}

.fieldSelect {
  display: block !important;
}

.name {
  display: flex;
  gap: 2%;
}

.name .label {
  left: 6%;
}

.input_container input,
.label .text {
  font-weight: 500;
  font-size: 0.78rem;
  color: #777777;
  /* margin-top: 15px; */
}

.input_container input:focus {
  outline: none;
  border: 1px solid #007aff;
}

.input_container input:focus + .label .text {
  transform: translate(0, -50%);
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
  color: #2b378d;
}

.lgn_stl {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icn_login {
  position: relative;
  top: 6px;
}

.label .text {
  transition: all 0.15s ease-out;
  /* font-weight: 600;
  transform: translate(0, -120%);
  background-color: rgb(255, 255, 255); */
}

.input_container input:focus + .label .text,
:not(input[value=""]) + .label .text {
  font-weight: 600;
  transform: translate(0, -120%);
  background-color: rgb(255, 255, 255);
}

.input_container label i {
  margin-left: 10rem;
  cursor: pointer;
}

.sign_up_btn {
  /* padding: 12px 63px; */
  padding: 0.56rem 0;
  width: 100%;
  background: #2b378d;
  border-radius: 4px;
  color: white;
  /* font-family: "Montserrat"; */
  font-weight: 600;
  font-size: 1rem;
  border: none;
  font-family: "Red Hat Display";
  margin-top: 1rem;
}

/* form stop ------------ */

/* input box styling stop ----------  */

/* ------------- footer section -------------- */
.t_c_text {
  font-weight: 400;
  font-size: 9.87838px;
  text-align: center;
  color: #2d3748;
  padding-bottom: 0;
  margin: 0.9rem;
}

.t_c_text a {
  text-decoration: none;
  color: #007aff;
  font-weight: 500;
}

.already_have_account {
  font-weight: 600;
  font-size: 12px;
  color: #2d3748;
  text-align: center;
  padding: 0;
  margin: 0;
}

.already_have_account a {
  text-decoration: none;
  color: #007aff;
}
