.sideNav {
  /* border: 1px solid blue; */
  min-height: 100vh;
}
.sidebarDrawer {
  min-height: 100vh;
  /* border: 1px solid red; */
}
.drawerOpen {
  border: 1px solid red;
  width: 240px;
  background-color: #0a2472;
}
/* .drawerClose {
  width: 40px;
} */
.listStyle {
  height: 100%;
  background-color: #0a2472;
  /* border: 1px solid red; */
}

.image_size {
  height: 1.3rem;
}
.image_icon {
  width: 23px;
  height: 25px;
  /* filter:invert(1); */
}

.marketplace_image_size {
  height: 1.1rem;
}

/* New CSS for layout */

/* Sidebar ------------------------------- */

.aside {
  max-height: 100vh;
  background-color: white;
}

aside .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  border-right: 1px solid #dedede;
}

aside .logo {
  display: flex;
  align-items: center;
}

aside .logo .logo-left {
  width: 100%;
  height: 2.2rem;
}

aside .close {
  display: none;
}

.sidebar {
  padding-top: 4rem;
  background-color: #0a2472;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0.8rem;
}

aside h3 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0px;
}

.linkStyle {
  text-decoration: none !important;
  color: white !important;
}

.sidebar a {
  display: flex;
  color: white;
  margin-left: 1rem;
  text-decoration: none;
  font-family: "Red Hat Display";
  gap: 1rem;
  position: relative;
  height: 3.7rem;
  align-items: center;
  transition: all 300ms ease;
}

/* .sidebar a img {
  transition: all 300ms ease;
}

.sidebar a:last-child {
  position: absolute;
  bottom: 2rem;
}

.sidebar a.active {
  background-color: rgba(139, 167, 252, 0.2);
  margin-left: 0;
}

.sidebar a.active:before {
  content: "";
  width: 5px;
  height: 100%;
  background-color: white;
}

.sidebar a.active img {
  color: var(white);
  margin-left: calc(1rem - 6px);
}

.sidebar a:hover img {
  margin-left: 0.8rem;
} */
