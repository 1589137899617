.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
}

.innerBox {
    width: 100%;
    max-width: 400px;
    padding: 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    box-sizing: border-box;
}

.logoContainer {
    display: flex;
    flex-direction: column; /* Stack logo and icon vertically */
    align-items: center; /* Center horizontally */
    margin-bottom: 20px;
}
.logoContainer2 {
    display: flex;
    flex-direction: column; /* Stack logo and icon vertically */
    align-items: center; /* Center horizontally */

}

.logo {
    width: 52%;
    height: auto;
}

.title {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 20px 0;
    color: #333;
}

.title2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 7px 0;
    color: #333;
}

.titleH6 {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 10px 0;
    color: #333;
}

.input {
    margin: 1.3rem 0;
}

.verifyButton {
    margin-top: 15px;
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    background: #2e3092;
    color: #FFFFFF;
}

.poweredBy {
    margin-top: 30px;
    font-size: 0.675rem;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.smallLogo {
    margin-top: 6px;
    width: 54px;
    height: auto;
}


.textShowDisp {

    color: #88888a;
    
    font-size: 0.68rem;
    line-height: 2;

}

.textShowDisp2 {
    
    font-size: 0.75rem;
     color: #e13939;
    font-weight: 600; 

        margin: 20px 0;
}

.textShowDispWarning{
        color: #ff0000;;
    font-weight: 600;
        font-size: 0.67rem;
}

.errorText {
    color: red;
    font-size: 0.875rem;
    margin-top: 8px;
    text-align: center;
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
    .innerBox {
        padding: 20px;
    }

    .title {
        font-size: 1.25rem;
    }

    .logo {
        width: 48%;
    }

    .verifyButton {
        padding: 10px;
        font-size: 0.95rem;
    }

    .poweredBy {
        font-size: 0.8rem;
    }
}
